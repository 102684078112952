import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'

const data = [
  {id: 1, icon: <FaAward/>, title: 'Experience', desc: '1 Year Studying'},
  {id: 2, icon: <TbBooks/>, title: 'Projects', desc: '3+ Completed'},
  {id: 3, icon: <BiHappyHeartEyes/>, title: 'Users', desc: 'Very happy users'},
]
export default data;
